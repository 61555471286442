<template>
  <div style="min-height: 880px;">
    <CRow>
      <CCol sm="12" lg="7">
        <div class="form-group form-row d-flex aling-items-center mb-0">
          <label class="col-form-label col-sm-12 col-lg-1 text-right mb-2">{{`${$t('label.date')}`}}</label>
          <div class="input-group col-sm-12 col-lg-4 mb-2">
            <vue-datepicker
              type="datetime"
              header
              :lang="this.$i18n.locale"
              :editable="false"
              :clearable="false"
              format="DD/MM/YYYY HH:mm"
              placeholder="DD/MM/YYYY HH:mm"
              time-title-format="DD/MM/YYYY HH:mm"
              v-model="form.FromDay"
              :disabled-date="validateDateRange"
              :append-to-body="false"
              value-type="format"
              :show-second="false"
              class="w-100"
            >
              <template #icon-calendar>
                <div style="display: none"></div>
              </template>
            </vue-datepicker>
          </div>
          <label class="col-form-label col-sm-12 col-lg-auto text-right mb-2">-</label>
          <div class="input-group col-sm-12 col-lg-4 mb-2">
            <vue-datepicker
              type="datetime"
              header
              :lang="this.$i18n.locale"
              :editable="true"
              :clearable="false"
              format="DD/MM/YYYY HH:mm"
              placeholder="DD/MM/YYYY HH:mm"
              time-title-format="DD/MM/YYYY HH:mm"
              v-model="form.ToDay"
              :disabled-date="validateDateRange"
              :append-to-body="false"
              value-type="format"
              :show-second="false"
              class="w-100"
            >
              <template #icon-calendar>
                <div style="display: none"></div>
              </template>
            </vue-datepicker>
          </div>
          <div class="d-flex align-items-start justify-content-end col-sm-12 col-lg-auto mb-2">
            <CButton
              color="watch"
              square
              size="sm"
              class="mx-1"
              @click="getYardWCargoStatisticsByTpClient(carousel)"
            >
              <CIcon name="cil-search"/>
            </CButton>
            <CButton
              color="danger"
              square
              size="sm"
              @click="Clear"
            >
              <CIcon name="cil-brush-alt"/>
            </CButton>
          </div>
        </div>
      </CCol>
    </CRow>
    <CRow class="m-0">
      <h5 class="col-sm-12 text-center text-bold px-0">{{ $t('label.customBroker') }}</h5>
      <CCol sm="12" class="px-1 mb-2">
        <div class="block-gray text-center">
          <div v-if="CurrentStatistic.length != 0">
            <div class="d-flex align-items-center pt-3">
              <div class="col-sm-12 col-lg-8">
                <PieDonut
                  Type="donut"
                  Width="100%"
                  Height="600px"
                  :Series="seriesCustomBroker"
                  :Label="labelCustomBroker"
                  :Colors="ColorCustomBroker"
                  :legend="legend"
                  :tooltip="tooltip"
                />
              </div>
              <div class="col-sm-12 col-lg-4 px-2">
                <div class="col-sm-12 px-0" style="height: 600px; overflow-y: auto;">
                  <table class="text-center px-1" style="color: #373d3f;">
                    <thead>
                      <tr>
                        <th></th>
                        <th class="text-left px-2">{{ $t('label.client') }}</th>
                        <th>{{ $t('label.quantity') }}</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr v-for="(Consignee, index) in CurrentStatistic?.CustomBrokerJson ?? []" :key="index">
                        <td>
                          <CRow class="justify-content-center align-items-center m-0">
                            <div class="circle-legend" :style="`background:${Consignee.Color};`"></div>
                          </CRow>
                        </td>
                        <td class="text-left px-2">{{ Consignee.CustomBrokerName }}</td>
                        <td>{{ formatNumber(Consignee.QuantityBl, 2) }}</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
          <div v-else class="d-flex justify-content-center align-items-center box-empty" style="color:#e55353">
            {{ $t('label.noDataAvailable').toUpperCase() }}
          </div>
        </div>
      </CCol>
    </CRow>
  </div>
</template>
<script>
import { mapState } from 'vuex';
import GeneralMixin from '@/_mixins/general';
import PieDonut from '../chart/pieDonut'
import { DateFormater, NumberFormater } from '@/_helpers/funciones';

//DATA-------------------------
function data() {
  return {
    CurrentStatistic: [],
  }
}

//methods
async function getYardWCargoStatisticsByTpClient() {
  this.$store.state.yardManagement.loading = true;
  this.CurrentStatistic = [];
  let ReportJson = {
    YardId: this.yardData.YardId,
    DateStart: this.form.FromDay ? DateFormater.formatDateTimeWithoutSlash(this.form.FromDay) : '',
    DateEnd: this.form.ToDay ? DateFormater.formatDateTimeWithoutSlash(this.form.ToDay) : '',
  }

  await this.$http.post('YardWCargoStatistics-by-TpClient', ReportJson, { root: 'ReportJson' })
    .then(response => {
      this.CurrentStatistic = response.data.data?.[0] ?? [];
    })
    .catch( err => {
      this.$notify({
        group: 'container',
        title: '¡Error!',
        text: err,
        type: "error"
      });
    })
    .finally(() => {
      this.$store.state.yardManagement.loading = false;
    })
}

function validateDateRange(date) {
  return date < this.form.PreviousDate || date > this.form.LaterDate;
}

function formatNumber(number, decimal) {
  return NumberFormater.formatNumber(number, decimal)
}

async function Clear() {
  await this.$emit('getDateRange');
  await this.getYardWCargoStatisticsByTpClient();
}

//computed
function seriesCustomBroker() {
  let CustomBrokerJson = this.CurrentStatistic?.CustomBrokerJson ?? [];
  return CustomBrokerJson.map(item => item.QuantityBl);
}

function labelCustomBroker() {
  let CustomBrokerJson = this.CurrentStatistic?.CustomBrokerJson ?? [];
  return CustomBrokerJson.map(item => item.CustomBrokerName);
}

function ColorCustomBroker() {
  let CustomBrokerJson = this.CurrentStatistic?.CustomBrokerJson ?? [];
  return CustomBrokerJson.map(item => item.Color);
}

function legend() {
  return {
    show: false,
  }
}

function tooltip() {
  return {
    enabled: true,
    y: {
      formatter: function (val, opts) {
        return NumberFormater.formatNumber(val, 2)
      }
    }
  }
}

export default {
  name: 'warehouse-yard-situation-customBroker',
  data,
  components: {
    PieDonut,
  },
  mixins: [GeneralMixin],
  props: {
    form: {
      type: Object,
      default: () => {},
    },
    active: Boolean,
  },
  methods: {
    getYardWCargoStatisticsByTpClient,
    validateDateRange,
    formatNumber,
    Clear,
  },
  computed: {
    seriesCustomBroker,
    labelCustomBroker,
    ColorCustomBroker,
    legend,
    tooltip,
    ...mapState({
      yardData: state => state.yardManagement.yardData,
      yardCollapse: state => state.yardManagement.yardCollapse,
    })
  },
  watch: {
    active: async function(newVal) {
      if(newVal) {
        await this.getYardWCargoStatisticsByTpClient();
      }
    },
  }
};
</script>
<style scoped>
.block-gray{
  width: 100%;
  background: rgb(198, 198, 201);
  padding-bottom: 1rem !important;
  padding-top: 0rem !important;
  border-radius: 5px;
}
.box-empty{
  width: 100%;
  height: 600px;
  padding-top: 1rem !important;
}
</style>